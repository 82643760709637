import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
    isLoggedIn: boolean;
    onLogin: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => (
    createStyles({
        root: {
            textAlign: 'center',
        },
        button: {
            margin: '20px',
        },
    })
));

export const Login = (props: Props) => {
    const [text, setText] = useState('');
    const [loginFailed, setLoginFailed] = useState(false);

    const classes = useStyles();

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setText(e.target.value);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // @note: password check on client is not safe!
        const success = text === 'eks';
        setLoginFailed(!success);
        setText('');
        props.onLogin(success);
    };

    const textWrongPW = loginFailed
        ? 'Falsches Passwort! Bitte nochmal versuchen...'
        : '';

    return <div className={classes.root}>
        <h2>Zutritt nur für Berechtigte:</h2>
        <form onSubmit={handleSubmit}>
            <TextField
                type="password"
                label="Passwort"
                autoFocus
                value={text}
                onChange={onTextChange}
            />
            <br />
            <Button
                color="primary"
                onClick={handleSubmit}
                className={classes.button}
            >
                Login
            </Button>
        </form>
        {textWrongPW}
    </div>
}