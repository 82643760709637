import React from 'react';
//import ReactMarkdown from 'react-markdown'
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface IOffer {
    name: string,
    price?: string,
    garnish?: GarnishProps

}

interface GarnishProps {
    name: string
    price?: string
}


interface Props {
    offer: IOffer
}

const useStyles = makeStyles((theme: Theme) => ({
    ul: {
        marginTop: '0pt',
        marginBottom: '5pt', // @todo
        paddingLeft: 16,
    },
})
)

const Garnish = (props: GarnishProps) => {
    const classes = useStyles();
    const { name, price } = props;
    return (
        <ul className={classes.ul}>
            <li>
                {name}
                {price && ' (' + price + ')'}
            </li>
        </ul>
    )
}

export function Offer(props: Props) {
    const { name, price, garnish } = props.offer;
    return (
        <>
            {name}
            {price ? ' (' + price.replace(' €', ' €') + ')' : ''}
            {garnish &&
                <Garnish
                    name={garnish.name}
                    price={garnish.price?.replace(' €', ' €')}
                />
            }
        </>)
}
