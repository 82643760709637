import React from 'react';
import { useLocalStorage } from '../../hooks'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MuiThemeProvider, CssBaseline, createMuiTheme, useMediaQuery } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
// own components
import Header from './Header'
import { Login } from '../Login'
import { ProviderList } from '../ProviderList'

const App = () => {

  const [loggedIn, setLoggedIn] = useLocalStorage('isLoggedIn', false)
  const [searchTerm, setSearchTerm] = useLocalStorage('searchTerm', '')
  const [onlyTodaysDishes, setOnlyTodaysDishes] = useLocalStorage('onlyTodaysDishes', true);
  const [darkMode, setDarkMode]
    = useLocalStorage('darkMode', useMediaQuery('(prefers-color-scheme: dark)'))

  const onLogin = setLoggedIn
  const onSearchTermChanged = setSearchTerm
  const onOnlyTodaysDishesChanged = setOnlyTodaysDishes
  const onDarkModeChanged = setDarkMode

  const theme = createMuiTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      primary: {
        main: blue[900]
      },
    },
    typography: {
      fontSize: 16,
    },
    props: {
      MuiButton: {
        variant: "outlined"
      },
      MuiToolbar: {
        variant: "dense",
      },
      MuiTextField: {
        margin: "dense",
      },
    }
  });

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        minHeight: '100vh',
        alignItems: 'center',
      },
      providerlist: {
        width: '100%',
      },
    })
  );

  const classes = useStyles();

  if (!loggedIn) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Header
          darkMode={darkMode}
          onDarkModeChanged={onDarkModeChanged}
        />
        <Login onLogin={onLogin} isLoggedIn={loggedIn} />
      </MuiThemeProvider>
    );
  };
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Header
          searchTerm={searchTerm}
          onSearchTermChanged={onSearchTermChanged}
          onlyTodaysDishes={onlyTodaysDishes}
          onOnlyTodaysDishesChanged={onOnlyTodaysDishesChanged}
          darkMode={darkMode}
          onDarkModeChanged={onDarkModeChanged}
        />
        <div className={classes.providerlist}>
          <ProviderList
            searchTerm={searchTerm}
            onlyTodaysDishes={onlyTodaysDishes}
          />
        </div>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
