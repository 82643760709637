import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
//import ReactMarkdown from 'react-markdown'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface ICarte {
    title: string,
    pages: {
        src: string,
        thumbnail: string,
        caption: string,
        orientation: string
    }[]
}

interface Props {
    carte?: ICarte | null,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        thumb: {
            cursor: 'pointer', // @todo not working?!
            marginRight: '8px',
            //marginBottom: '10px'
        },
    })
);


export function DishesCarte(props: Props) {
    const classes = useStyles();
    const [photoIndex, setPhotoIndex] = useState(0);
    const [open, setOpen] = useState(false);

    if (!props.carte) return null;
    const { title, pages } = props.carte;

    let thumbs = [];
    for (let i = 0; i < pages.length; ++i) {
        const idx = i;
        const page = pages[i];
        thumbs.push(
            <img key={i}
                className={classes.thumb}
                src={page.thumbnail}
                title={page.caption}
                alt={""}
                onClick={() => { setOpen(true); setPhotoIndex(idx) }}
            />
        )
    }

    return (
        <div>
            {<h3 className="dishes-title">{title}</h3>}
            {thumbs}
            {open && (
                <Lightbox
                    mainSrc={pages[photoIndex].src}
                    nextSrc={photoIndex < pages.length - 1 ? pages[photoIndex + 1].src : ""}
                    prevSrc={photoIndex ? pages[photoIndex - 1].src : ""}
                    onCloseRequest={() => setOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + pages.length - 1) % pages.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % pages.length)
                    }
                />
            )}
        </div>
    )
}
