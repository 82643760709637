import React from 'react';
import ReactMarkdown from 'react-markdown'
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

export interface InfoItem {
    text: string,
    type: string,
    highlightDay?: string,
    summary?: string
}

interface Props {
    weekDay: number,
    infos?: (string | InfoItem)[],
}

const useStyles = makeStyles((theme: Theme) => ({
    success: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'underline'
    },
    ul: {
        //paddingInlineStart: 0,
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingLeft: 16,
    },
    details: {
        marginLeft: -16,
        cursor: 'pointer',
    },
})
)

export function InfoList(props: Props) {
    const classes = useStyles();
    const { infos, weekDay } = props;

    if (!infos || !infos.length) return null;

    const makeInfoItem = (item: string | InfoItem, key: number) => {
        if (typeof (item) == 'string') {
            return <li key={key}>
                <ReactMarkdown
                    source={item}
                    renderers={{
                        link: props => (<Link className={classes.link} {...props} />)
                    }}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                />
            </li>;
        }
        const markdown =
            <ReactMarkdown
                source={item.text}
                renderers={{
                    link: props => (<Link className={classes.link} {...props} />)
                }}
                disallowedTypes={['paragraph']}
                unwrapDisallowed
            />;
        let itemClass;
        switch (item.type) {
            case "details":
                return <li key={key} style={{ listStyleType: "none" }}>
                    <details className={classes.details}>
                        <summary>{item.summary || ""}</summary>
                        {markdown}
                    </details>
                </li>;
            case "highlightPositive":
                itemClass = classes.success;
                break;
            case "highlightNegative":
                itemClass = classes.error;
                break;
            default:
                console.log("InfoList: unkonw type " + item.type + "!")
                break; // @todo
        }
        const weekDays = [
            "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"
        ]
        var highlightDay = "";
        if (item.highlightDay !== undefined)
            highlightDay = weekDays[weekDay]
        if (highlightDay !== item.highlightDay) {
            return <li key={key}>{markdown}</li>;
        }
        return <li key={key} className={itemClass}>{markdown}</li>;
    }

    return (
        <ul className={classes.ul} >
            {infos.map(makeInfoItem)}
        </ul>
    );
}
