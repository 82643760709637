import React from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid } from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { ExpandMore, Phone, Launch } from '@material-ui/icons';
// own components
import { InfoList, InfoItem } from './InfoList'
import { Dishes, IDishes } from './Dishes'

export interface IProvider {
    title: string,
    id: string,
    phoneNumber?: string,
    website?: string,
    infos?: (string | InfoItem)[],
    keywords: string[],
    dishes?: IDishes
}

interface Props {
    date: Date,
    onlyTodaysDishes: boolean;
    provider: IProvider,
}

// @todo clean up
const useStyles = makeStyles((theme: Theme) => ({
    contactitem: {
        marginRight: 8,
    },
    icon: {
        marginRight: '4px',
        //fontSize: 'inherit',
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
})
)

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        margin: '1px',
        //marginBottom: -1,
        minHeight: '35px',
        //height: '56px',
        '&$expanded': {
            minHeight: '35px'
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

export function Provider(props: Props) {
    const classes = useStyles();
    const { date, onlyTodaysDishes } = props;
    const { title, id, phoneNumber, website, infos, dishes } = props.provider;

    const phone = phoneNumber &&
        <span className={classes.contactitem}>
            <Phone className={classes.icon} />
            <Link className={classes.link}
                href={`tel:${phoneNumber.replace(/ /g, "").replace(/^0/, "+49")}`}
            >
                {phoneNumber}
            </Link>
        </span>
    const web = website &&
        <span className={classes.contactitem}>
            <Launch className={classes.icon} />
            <Link className={classes.link}
                href={`https://www.` + website}
            >
                {website}
            </Link>
        </span>

    return (
        <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
            >
                <Typography variant='h6'>{title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        {phone} {web}
                        <InfoList weekDay={date.getDay()} infos={infos} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Dishes date={date} id={id} onlyTodaysDishes={onlyTodaysDishes}
                            dishes={dishes}
                        />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}
