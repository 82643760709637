import React from 'react';
import {
  TextField, Checkbox, FormControlLabel,
  Button, Menu, MenuItem
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Tooltip, Theme } from '@material-ui/core';
// own components
import HideOnScroll from "./HideOnScroll";

interface Props {
  searchTerm?: string;
  onSearchTermChanged?: (value: string) => void;
  onlyTodaysDishes?: boolean | undefined;
  onOnlyTodaysDishesChanged?: (value: boolean) => void;
  darkMode?: boolean
  onDarkModeChanged?: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      color: "white",
    },
    title: {
      fontWeight: "bold",
      paddingRight: 15,
    },
    space: {
      flexGrow: 1,
    },
    checkBox: {
    },
    button: {
      color: "white !important",
      border: 0,
    },
  }),
);

const WhiteTextField = withStyles(theme => ({
  root: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff8', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary.main, // Solid underline on focus
    },
  },
}))(TextField);


export default function Header(props: Props) {
  const classes = useStyles();

  let searchField;
  if (props.searchTerm !== undefined && props.onSearchTermChanged !== undefined) {

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onSearchTermChanged !== undefined) {
        props.onSearchTermChanged(e.target.value)
      }
    };

    const handleSearchTermSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault()
    };

    searchField = (
      <form
      onSubmit={handleSearchTermSubmit}
      >
        <WhiteTextField
          //label="Suche"
          placeholder="Suche…"
          type="search"
          autoFocus
          //fullWidth
          onChange={handleSearchTermChange}
          value={props.searchTerm}
        />
      </form>)
  }

  let handleOnlyTodaysDishesChange, onlyTodaysDishes;
  if (props.onlyTodaysDishes !== undefined && props.onOnlyTodaysDishesChanged !== undefined) {

    handleOnlyTodaysDishesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onOnlyTodaysDishesChanged !== undefined) {
        props.onOnlyTodaysDishesChanged(e.target.checked);
      }
    };

    onlyTodaysDishes =
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox className={classes.checkBox}
              checked={props.onlyTodaysDishes}
              onChange={handleOnlyTodaysDishesChange}
              name=""
              color="secondary"
            />
          }
          label="Nur heutige Tagesessen"
        />
      </MenuItem>
  }

  let handleDarkModeChange, darkMode;
  if (props.darkMode !== undefined && props.onDarkModeChanged !== undefined) {

    handleDarkModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onDarkModeChanged) {
        props.onDarkModeChanged(e.target.checked);
      }
    };

    darkMode = <MenuItem>
      <FormControlLabel
        control={
          <Checkbox className={classes.checkBox}
            checked={props.darkMode}
            onChange={handleDarkModeChange}
            name=""
            color="secondary"
          />
        }
        label="Dark Theme"
      />
    </MenuItem>
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <HideOnScroll>
        <AppBar position="fixed">
          <Toolbar className={classes.toolbar}>
            <Tooltip arrow title="Rudolphs Fressnapf" placement="bottom">
              <Typography className={classes.title}>
                RF::Lunch
            </Typography>
            </Tooltip>
            <Typography className={classes.space} />
            {searchField}
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.button}>
              <MenuIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {onlyTodaysDishes}
              {darkMode}
            </Menu>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
}
