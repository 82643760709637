import React, { useState, useEffect } from 'react';
import { Provider, IProvider } from './Provider'

interface Props {
    searchTerm: string;
    onlyTodaysDishes: boolean;
}

export const ProviderList = (props: Props) => {
    const [date, setDate] = useState(new Date());
    const [providers, setProviders] = useState<IProvider[] | null>();

    useEffect(() => {
        setTimeout(() => {
            setDate(new Date());
        }, 1000 * 60 * 60) // update every hour
    })

    const fetchDishes = (provider: IProvider) => new Promise<IProvider>((resolve) => {
        //if (dishes && dishes.dateEnd && dishes.dateEnd >= new Date()) return;
        //console.log("fetch " + provider.id)
        fetch("https://rf-lunch.hasels.de/api/dishes/index.php/?name=" + provider.id)
            .then((res) => {
                if (!res.ok) throw Error(res.statusText); // @todo
                return res.json();
            })
            .then(dishes => {
                if (dishes === {} /*|| !(dishes.dateStart && dishes.dateEnd)*/) {
                    return null;
                }
                // @todo store in local storage
                dishes.dateStart = new Date(dishes.dateStart)
                dishes.dateEnd = new Date(dishes.dateEnd)
                provider.dishes = dishes
                resolve(provider)
            })
    })

    const getAllDishes = (providers: IProvider[]) => {
        return Promise.all(providers.map(provider => fetchDishes(provider)))
    }

    useEffect(() => {
        fetch('https://rf-lunch.hasels.de/api/providers/')
            .then((res) => {
                if (!res.ok) throw Error(res.statusText);
                return res.json()
            })
            .then((providers: IProvider[]) => {
                getAllDishes(providers).then(providers => {
                    setProviders(providers)
                })
            })
    }, []);

    if (!providers) {
        return (<>Lade Anbieter…</>)
    }

    if (!providers.length) {
        return (<>Fehler: Server hat keine Anbieter zurückgegeben!</>)
    }

    const providersFiltered = !props.searchTerm
        ? providers
        : providers.filter(provider =>
            provider.keywords.some(
                keyword => keyword.toLowerCase().includes(props.searchTerm.toLocaleLowerCase()))
        );

    return (
        <>
            {providersFiltered.map(provider =>
                <Provider
                    key={provider.id}
                    date={date}
                    provider={provider}
                    onlyTodaysDishes={props.onlyTodaysDishes}
                />)}
        </>
    );
}