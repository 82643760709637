import * as React from "react";
import { Slide, useScrollTrigger } from "@material-ui/core";

interface Props {
    children: React.ReactElement;
}

const HideOnScroll = (props: Props) => {
    const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
