import React, { useState, useEffect } from 'react';
//import ReactMarkdown from 'react-markdown'
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Offer, IOffer } from './Offer'
import { DishesCarte, ICarte } from './DishesCarte'

export interface IDishes {
    dateStart?: Date,
    dateEnd?: Date,
    offers: {
        title?: string,
        titleWeek?: string,
        monday?: IOffer[],
        tuesday?: IOffer[],
        wednesday?: IOffer[],
        thursday?: IOffer[],
        friday?: IOffer[],
        saturday?: IOffer[],
        sunday?: IOffer[],
        week?: IOffer[],
    } | null,
    carte: ICarte | null,
    error?: string
}

interface Props {
    date: Date,
    id: string,
    onlyTodaysDishes: boolean;
    dishes?: IDishes
}

const useStyles = makeStyles((theme: Theme) => ({
    ul: {
        marginTop: '0pt',
        marginBottom: '5pt', // @todo
        paddingLeft: 16,
    },
    h3: {
        marginTop: 5,
        marginBottom: 5
    }
})
);

export function Dishes(props: Props) {
    const classes = useStyles();
    const { id/*, date*/, onlyTodaysDishes, dishes } = props;

/*
    useEffect(() => {
        console.log(id)
        if (dishes && dishes.dateEnd && dishes.dateEnd >= new Date()) return;
        console.log("fetch " + id)
        let mounted = true;
        fetch("https://rf-lunch.hasels.de/api/dishes/index.php/?name=" + id)
            .then((res) => {
                if (!res.ok) throw Error(res.statusText); // @todo
                return res.json();
            })
            .then(dishes => {
                //console.log(dishes)
                if (dishes === {} /*|| !(dishes.dateStart && dishes.dateEnd)* /) {
                    return null;
                }
                if (mounted) {
                    setDishes({
                        ...dishes,
                        dateStart: new Date(dishes.dateStart),
                        dateEnd: new Date(dishes.dateEnd),
                    })
                }
            })
        return () => { mounted = false; return; }
    }, [id]);
    */

    if (!dishes) return ( // @todo
        <div>
            Fehler: keine Gerichte vom Server bekommen
        </div>
    );

    if (dishes.error) {
        console.log(dishes.error)
        return <div></div>
    }
    // function to create list of offers of one day or week's offers, respectively
    const offerGroup = ((offers: IOffer[] | undefined, key: string) => {
        if (!offers || !offers.length) return;
        if (offers.length === 1) return <Offer key={offers[0].name} offer={offers[0]} />;
        const items = offers.map(offer => {
            return <Offer key={offer.name} offer={offer} />
        });
        return (
            <ul key={key} className={classes.ul}>{items.map(item => {
                if (!item) return null;
                return <li key={item.key || ""}>{item}</li>
            })}
            </ul>)
    })

    let items = []
    // add daily offers
    let dailyItems = []
    if (onlyTodaysDishes) {
        let offers;
        switch (new Date().getDay()) {
            case 0: offers = dishes.offers?.sunday; break;
            case 1: offers = dishes.offers?.monday; break;
            case 2: offers = dishes.offers?.tuesday; break;
            case 3: offers = dishes.offers?.wednesday; break;
            case 4: offers = dishes.offers?.thursday; break;
            case 5: offers = dishes.offers?.friday; break;
            case 6: offers = dishes.offers?.saturday; break;
        }
        const offersOfDay = offerGroup(offers, "day");
        if (offersOfDay) {
            dailyItems.push(offersOfDay)
        }
    } else {
        const dailyOffers = {
            "Montag": dishes.offers?.monday,
            "Dienstag": dishes.offers?.tuesday,
            "Mittwoch": dishes.offers?.wednesday,
            "Donnerstag": dishes.offers?.thursday,
            "Freitag": dishes.offers?.friday,
            "Samstag": dishes.offers?.saturday,
            "Sonntag": dishes.offers?.sunday,
        }
        for (let [day, offers] of Object.entries(dailyOffers)) {
            const offersOfDay = offerGroup(offers, "day");
            if (offersOfDay) {
                dailyItems.push(
                    <li key={day}>
                        {day + ": "}
                        {offersOfDay} {/* @todo avoid div*/}
                    </li>)
            }
        }
    }
    if (dailyItems.length > 0) {
        let str = dishes.offers?.title;
        if (dishes.dateStart?.getTime() && dishes.dateEnd?.getTime()) {
            if (!onlyTodaysDishes) {
                str += ' (' + dishes.dateStart.toLocaleDateString('de') + ' - '
                    + dishes.dateEnd.toLocaleDateString('de') + ')'
            } else {
                const weekday = new Date().getDay()
                var date = new Date(dishes.dateStart)
                date.setDate(
                    dishes.dateStart.getDate() + (weekday ? weekday - 1 : 6))
                str += ' (' + date.toLocaleDateString('de') + ')'
            }
        }
        items.push(dishes.offers?.title &&
            <h3 key="title" className={classes.h3}>{str}</h3>);
        if (dailyItems.length > 1) items.push(<ul key="dishes" className={classes.ul}>{dailyItems}</ul>)
        else items.push(dailyItems[0])
    }
    // add week's offers
    if (dishes.offers?.week) {
        items.push(dishes.offers?.titleWeek && <h3 key="titleWeek" className={classes.h3}>{dishes.offers?.titleWeek}</h3>);
        const weeksOffers = offerGroup(dishes.offers?.week, "week");
        items.push(<div key="week">{weeksOffers}</div>) // @todo avoid div
    }
    // add imgage gallery
    items.push(<DishesCarte key="carte" carte={dishes.carte} />)
    return (<>{items}</>);
}
